<template>
  <div class="home" :key="key">
    <div v-if="role == 'product_vendor'">
      <v-row>
        <v-col cols="12" md="4" sm="6">
          <v-card class="pa-4 pb-7 rounded-lg">
            <div class="d-flex mb-2">
              <div class="text--text mt-2">Sales today</div>
              <v-spacer></v-spacer>
              <v-img height="48" max-width="48" contain src="@/assets/icon/main-sales.svg"></v-img>
            </div>
            <div class="d-flex align-end">
              <span class="text-h5 font-weight-bold">SAR {{ cardList.sales_today ? (cardList.sales_today / 100).toFixed(2) : 0 }}</span>
              <span class="f13 ps-2" :class="cardList.sales_difference ? 'success--text' : 'gray--text'" style="padding-bottom: 2px">
                {{ cardList.sales_difference ? `+${cardList.sales_difference}` : 0 }}
              </span>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <v-card class="pa-4 pb-7 rounded-lg">
            <div class="d-flex mb-2">
              <div class="text--text mt-2">Orders today</div>
              <v-spacer></v-spacer>
              <v-img height="48" max-width="48" contain src="@/assets/icon/main-cart.svg"></v-img>
            </div>
            <div class="d-flex align-end">
              <span class="text-h5 font-weight-bold">{{ cardList.product_orders_today ? cardList.product_orders_today : 0 }}</span>
              <span
                class="f13 ps-2"
                :class="cardList.product_orders_difference ? 'success--text' : 'gray--text'"
                style="padding-bottom: 2px"
              >
                {{ cardList.product_orders_difference ? `+${cardList.product_orders_difference}` : 0 }}
              </span>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <v-card class="pa-4 pb-7 rounded-lg">
            <div class="d-flex mb-2">
              <div class="text--text mt-2">Listed products</div>
              <v-spacer></v-spacer>
              <v-img height="48" max-width="48" contain src="@/assets/icon/main-cube.svg"></v-img>
            </div>
            <div class="text-h5 font-weight-bold">
              <span>{{ cardList.listed_products ? cardList.listed_products : 0 }}</span>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-card class="rounded-lg mt-5">
        <div class="d-flex flex-wrap align-center pa-3">
          <div class="f18 font-weight-bold">Orders</div>
          <v-spacer></v-spacer>
          <v-select
            @change="getProducts"
            class="home-select gray lighten-4"
            v-model="productFilter"
            :items="itemsSelect"
            item-text="title"
            item-value="id"
            dense
            height="28"
            outlined
            hide-details
          ></v-select>
        </div>
        <v-chartist type="Bar" :data="chartDataProducts" :options="chartOptions"></v-chartist>
      </v-card>
      <v-card class="mt-5">
        <div class="f18 font-weight-bold pa-3">Latest orders</div>
        <v-divider></v-divider>
        <v-card tile flat color="transparent" v-if="!ordersTable.pages" class="d-flex justify-center align-center" height="350">
          <div>
            <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
            <div class="text-center text--text mt-4">Sorry, but nothing found</div>
          </div>
        </v-card>
        <div v-else>
          <v-data-table
            hide-default-footer
            fixed-header
            :headers="headersOrder"
            :items="arrayOrdersTable"
            :items-per-page="-1"
            item-key="id"
            height="350"
            class="d-sm-block d-none"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id" class="link" @click="openBooking(item.id)" height="50">
                  <td>{{ item.number }}</td>
                  <td>{{ item.customer ? `${item.customer.first_name} ${item.customer.last_name}` : '' }}</td>
                  <td>SAR {{ item.total / 100 }}</td>
                  <td class="text-capitalize">{{ item.status }}</td>
                  <td>
                    {{ new Date(item.submitted_at).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) }}
                  </td>
                </tr>
                <tr v-if="pageOrder != ordersTable.pages">
                  <td class="text-center" colspan="6">
                    <v-btn @click="++pageOrder, getOrdersTable()" text>View more</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <div class="d-block d-sm-none">
            <div v-for="item in arrayOrdersTable" :key="item.id" @click="openBooking(item.id)" class="px-4 pt-2">
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">Number</div>
                <div class="font-weight-medium f18">
                  {{ item.number }}
                </div>
              </div>
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">Customer</div>
                <div class="font-weight-medium f18">
                  {{ item.customer ? `${item.customer.first_name} ${item.customer.last_name}` : '' }}
                </div>
              </div>
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">Total</div>
                <div class="font-weight-medium f18">SAR {{ item.total / 100 }}</div>
              </div>
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">Status</div>
                <div class="font-weight-medium f18 text-capitalize">{{ item.status }}</div>
              </div>
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">Submitted at</div>
                <div class="font-weight-medium f18">
                  {{ new Date(item.submitted_at).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) }}
                </div>
              </div>
              <v-divider></v-divider>
            </div>
            <div v-if="pageOrder != ordersTable.pages" class="mt-2 pb-2 px-3 text-center">
              <v-btn @click="++pageOrder, getOrdersTable()" text>View more</v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12" :md="role != 'trip_organizer' ? 3 : 4" sm="6">
          <v-card class="py-2 px-3 rounded-lg">
            <div class="d-flex align-center mb-1">
              <v-img height="42" max-width="42" contain src="@/assets/icon/main-sales.svg"></v-img>
              <v-spacer></v-spacer>
              <div
                class="rounded-sm text-body-2 py-1 px-2"
                :class="
                  cardList.sales_difference
                    ? cardList.sales_difference > 0
                      ? 'success lighten-5 success--text'
                      : 'error lighten-5 error--text'
                    : 'gray lighten-3 text--text'
                "
              >
                SAR {{ (cardList.sales_difference / 100).toFixed(2) || 0 }}
              </div>
            </div>
            <div class="text-h5 font-weight-bold">SAR {{ (cardList.sales_today / 100).toFixed(2) || 0 }}</div>
            <div class="text--text">Sales today</div>
          </v-card>
        </v-col>
        <v-col cols="12" :md="role != 'trip_organizer' ? 3 : 4" sm="6">
          <v-card class="py-2 px-3 rounded-lg">
            <div class="d-flex align-center mb-1">
              <v-img height="42" max-width="42" contain src="@/assets/icon/main-booking.svg"></v-img>
              <v-spacer></v-spacer>
              <div
                class="rounded-sm text-body-2 py-1 px-2"
                :class="
                  cardList.bookings_difference
                    ? cardList.bookings_difference > 0
                      ? 'success lighten-5 success--text'
                      : 'error lighten-5 error--text'
                    : 'gray lighten-3 text--text'
                "
              >
                {{ cardList.bookings_difference || 0 }}
              </div>
            </div>
            <div class="text-h5 font-weight-bold">{{ cardList.bookings_today || 0 }}</div>
            <div class="text--text">Bookings today</div>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" sm="6" v-if="role != 'trip_organizer'">
          <v-card class="py-2 px-3 rounded-lg">
            <div class="d-flex align-center mb-1">
              <v-img height="42" max-width="42" contain src="@/assets/icon/main-customer.svg"></v-img>
              <v-spacer></v-spacer>
              <div
                class="rounded-sm text-body-2 py-1 px-2"
                :class="
                  cardList.customers_difference
                    ? cardList.customers_difference > 0
                      ? 'success lighten-5 success--text'
                      : 'error lighten-5 error--text'
                    : 'gray lighten-3 text--text'
                "
              >
                {{ cardList.customers_difference }}
              </div>
            </div>
            <div class="text-h5 font-weight-bold">{{ cardList.customers_today || 0 }}</div>
            <div class="text--text">New customers today</div>
          </v-card>
        </v-col>
        <v-col cols="12" :md="role != 'trip_organizer' ? 3 : 4" sm="6">
          <v-card class="py-2 px-3 rounded-lg">
            <div class="d-flex align-center mb-1">
              <v-img height="42" max-width="42" contain src="@/assets/icon/main-trips.svg"></v-img>
              <v-spacer></v-spacer>
            </div>
            <div class="text-h5 font-weight-bold">{{ cardList.active_trips || 0 }}</div>
            <div class="text--text">Active trips</div>
          </v-card>
        </v-col>
        <v-col cols="12" md="7" v-if="role != 'trip_organizer'">
          <v-card class="rounded-lg">
            <div class="d-flex flex-wrap align-center pa-3">
              <div class="f18 font-weight-bold">Customers</div>
              <v-spacer></v-spacer>
              <div class="d-flex align-center me-8">
                <v-card width="12" height="3" flat class="primary lighten-2 me-2"></v-card>
                <div class="text-caption">This week</div>
              </div>
              <div class="d-flex align-center">
                <v-card width="12" height="3" flat class="gray lighten-1 me-2"></v-card>
                <div class="text-caption">Previous week</div>
              </div>
              <v-spacer></v-spacer>
              <v-select
                @change="getCustomer"
                class="home-select gray lighten-4"
                v-model="customerFilter"
                :items="itemsSelect"
                item-text="title"
                item-value="id"
                dense
                height="28"
                outlined
                hide-details
              ></v-select>
            </div>
            <v-chartist type="Line" :data="chartDataCustomer" :options="chartOptions"></v-chartist>
          </v-card>
        </v-col>
        <v-col cols="12" :md="role != 'trip_organizer' ? 5 : 12">
          <v-card class="rounded-lg">
            <div class="d-flex flex-wrap align-center pa-3">
              <div class="f18 font-weight-bold">Bookings</div>
              <v-spacer></v-spacer>
              <div class="d-flex align-center me-8">
                <v-card width="12" height="3" flat class="primary lighten-2 me-2"></v-card>
                <div class="text-caption">This week</div>
              </div>
              <div class="d-flex align-center">
                <v-card width="12" height="3" flat class="gray lighten-1 me-2"></v-card>
                <div class="text-caption">Previous week</div>
              </div>
              <v-spacer></v-spacer>
              <v-select
                @change="getBooking"
                class="home-select gray lighten-4"
                v-model="bookingFilter"
                :items="itemsSelect"
                item-text="title"
                item-value="id"
                dense
                height="28"
                outlined
                hide-details
              ></v-select>
            </div>
            <v-chartist type="Bar" :data="chartDataBooking" :options="chartOptions"></v-chartist>
          </v-card>
        </v-col>
        <v-col cols="12" md="5" v-if="role != 'trip_organizer'">
          <v-card class="rounded-lg">
            <div class="f18 font-weight-bold pa-3">Payments</div>
            <v-divider></v-divider>
            <v-card flat height="310" color="view-without-scroll">
              <div v-for="item in arrayPay" :key="item.id" @click="openPayment(item.id)" class="px-4 pt-2 link">
                <div class="d-flex mb-1">
                  <div class="text-body-2 font-weight-bold">
                    {{ item.customer ? `${item.customer.first_name} ${item.customer.last_name}` : '' }}
                  </div>
                  <v-spacer></v-spacer>
                  <div class="text-body-2 text-uppercase">{{ item.amount_currency }} {{ (item.amount / 100).toFixed(2) }}</div>
                </div>
                <div class="d-flex align-end">
                  <div class="text-body-2 text-capitalize text--text">{{ item.status }}</div>
                  <v-spacer></v-spacer>
                  <img
                    height="14"
                    v-if="item.method == 'visa_master' || item.method == 'payment_card'"
                    src="@/assets/icon/master.png"
                    alt="payIcon"
                  />
                  <img height="14" v-if="item.method" :src="getImage(item.method)" alt="payIcon" />
                </div>
                <v-divider class="mt-2"></v-divider>
              </div>
              <div v-if="pagePay != paymentList.pages" class="text-center my-2">
                <v-btn @click="++pagePay, getPaymentsList()" text>View more</v-btn>
              </div>
            </v-card>
          </v-card>
        </v-col>
        <v-col cols="12" md="7" v-if="role != 'trip_organizer'">
          <v-card class="rounded-lg">
            <div class="f18 font-weight-bold pa-3">Submitted trip requests</div>
            <v-divider></v-divider>
            <v-card tile flat color="transparent" v-if="!requestsTable.pages" class="d-flex justify-center align-center" height="310">
              <div>
                <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
                <div class="text-center text--text mt-4">Sorry, but nothing found</div>
              </div>
            </v-card>
            <div v-else>
              <v-data-table
                hide-default-footer
                fixed-header
                :headers="headersRequest"
                :items="arrayRequestsTable"
                :items-per-page="-1"
                item-key="id"
                height="310"
                class="d-sm-block d-none"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.id" class="link" @click="openRequest(item.id)" height="50">
                      <td>{{ item.person.first_name }} {{ item.person.last_name }}</td>
                      <td>
                        {{ new Date(item.date_from).toLocaleString('en-GB', { month: 'short', day: 'numeric' }) }}
                        - {{ new Date(item.date_to).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) }}
                      </td>
                      <td>{{ item.departure_country.name }}, {{ item.departure_city.name }}</td>
                    </tr>
                    <tr v-if="pageRequest != requestsTable.pages">
                      <td class="text-center" colspan="6">
                        <v-btn @click="++pageRequest, getRequestsTable()" text>View more</v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
              <div class="d-block d-sm-none">
                <div v-for="item in arrayRequestsTable" :key="item.id" @click="openRequest(item.id)" class="px-4 pt-2">
                  <div class="d-flex my-1">
                    <div class="opasity--text f18 font-weight-medium label-table">Name</div>
                    <div class="font-weight-medium f18">{{ item.person.first_name }} {{ item.person.last_name }}</div>
                  </div>
                  <div class="d-flex my-1">
                    <div class="opasity--text f18 font-weight-medium label-table">Expected dates</div>
                    <div class="font-weight-medium f18 text-capitalize">
                      {{ new Date(item.date_from).toLocaleString('en-GB', { month: 'short', day: 'numeric' }) }}
                      - {{ new Date(item.date_to).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) }}
                    </div>
                  </div>
                  <div class="d-flex my-1">
                    <div class="opasity--text f18 font-weight-medium label-table">Departure from</div>
                    <div class="font-weight-medium f18">{{ item.departure_country.name }}, {{ item.departure_city.name }}</div>
                  </div>
                  <v-divider></v-divider>
                </div>
                <div v-if="pageRequest != requestsTable.pages" class="mt-2 pb-2 px-3 text-center">
                  <v-btn @click="++pageRequest, getRequestsTable()" text>View more</v-btn>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-card class="mt-4">
        <div class="f18 font-weight-bold pa-3">Bookings</div>
        <v-divider></v-divider>
        <v-card tile flat color="transparent" v-if="!bookingTable.pages" class="d-flex justify-center align-center" height="350">
          <div>
            <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
            <div class="text-center text--text mt-4">Sorry, but nothing found</div>
          </div>
        </v-card>
        <div v-else>
          <v-data-table
            hide-default-footer
            fixed-header
            :headers="headersBooking"
            :items="arrayBookingTable"
            :items-per-page="-1"
            item-key="id"
            height="350"
            class="d-sm-block d-none"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id" class="link" @click="openBooking(item.id)" height="50">
                  <td>{{ item.customer ? `${item.customer.first_name} ${item.customer.last_name}` : '' }}</td>
                  <td>{{ item.trip ? item.trip.title : '' }}</td>
                  <td>{{ item.plan_type ? item[`${item.plan_type}_plan`].title_en : '' }}</td>
                  <td>
                    {{ new Date(item.starts_at).toLocaleString('en-GB', { month: 'short', day: 'numeric' }) }}
                    -
                    {{ new Date(item.ends_at).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) }}
                  </td>
                  <td class="text-capitalize">{{ item.status }}</td>
                  <td>
                    {{ new Date(item.created_at).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) }}
                  </td>
                </tr>
                <tr v-if="page != bookingTable.pages">
                  <td class="text-center" colspan="6">
                    <v-btn @click="++page, getBookingTable()" text>View more</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <div class="d-block d-sm-none">
            <div v-for="item in arrayBookingTable" :key="item.id" @click="openBooking(item.id)" class="px-4 pt-2">
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">Name</div>
                <div class="font-weight-medium f18">
                  {{ item.customer ? `${item.customer.first_name} ${item.customer.last_name}` : '' }}
                </div>
              </div>
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">Trip</div>
                <div class="font-weight-medium f18">{{ item.trip ? item.trip.title : '' }}</div>
              </div>
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">Plan</div>
                <div class="font-weight-medium f18">{{ item.plan_type ? item[`${item.plan_type}_plan`].title_en : '' }}</div>
              </div>
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">Trip dates</div>
                <div class="font-weight-medium f18">
                  {{ new Date(item.starts_at).toLocaleString('en-GB', { month: 'short', day: 'numeric' }) }}
                  -
                  {{ new Date(item.ends_at).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) }}
                </div>
              </div>
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">Status</div>
                <div class="font-weight-medium f18 text-capitalize">{{ item.status }}</div>
              </div>
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">Created at</div>
                <div class="font-weight-medium f18">
                  {{ new Date(item.created_at).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) }}
                </div>
              </div>
              <v-divider></v-divider>
            </div>
            <div v-if="page != bookingTable.pages" class="mt-2 pb-2 px-3 text-center">
              <v-btn @click="++page, getBookingTable()" text>View more</v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import VueChartist from 'v-chartist';
import Chartist from 'chartist';
import ChartistTooltip from 'chartist-plugin-tooltips';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    'v-chartist': VueChartist,
  },
  data() {
    return {
      key: 1,
      page: 1,
      pagePay: 1,
      pageRequest: 1,
      pageOrder: 1,
      chartDataCustomer: {
        labels: [],
        series: [],
      },
      chartDataBooking: {
        labels: [],
        series: [],
      },
      chartDataProducts: {
        labels: [],
        series: [],
      },
      chartOptions: {
        height: '308px',
        fullWidth: true,
        plugins: [Chartist.plugins.tooltip()],
      },
      customerFilter: '1w',
      bookingFilter: '1w',
      productFilter: '1w',
      itemsSelect: [
        { id: '1w', title: 'Week' },
        { id: '1m', title: 'Month' },
        { id: '1y', title: 'Year' },
      ],
      headersRequest: [
        { text: 'Name', value: 'person', sortable: false },
        { text: 'Expected dates', value: 'date_from', sortable: false },
        { text: 'Departure from', value: 'departure_city', sortable: false },
      ],
      headersBooking: [
        { text: 'Name', value: 'customer', sortable: false },
        { text: 'Trip', value: 'trip', sortable: false },
        { text: 'Plan', value: 'plan', sortable: false },
        { text: 'Dates', value: 'starts_at', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Created at', value: 'created_at', sortable: false },
      ],
      headersOrder: [
        { text: 'Number', value: 'number', sortable: false },
        { text: 'Customer', value: 'customer', sortable: false },
        { text: 'Total', value: 'total', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Submitted at', value: 'submitted_at', sortable: false },
      ],
      arrayPay: [],
      arrayRequestsTable: [],
      arrayBookingTable: [],
      arrayOrdersTable: [],
      error: [],
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.role == 'product_vendor') {
        this.getProducts();
        this.getOrdersTable();
      } else {
        this.getCustomer();
        this.getBooking();
        this.getPaymentsList();
        this.getRequestsTable();
        this.getBookingTable();
      }
      this.$store.dispatch('getStatisticsCounters');
    }, 300);
  },
  methods: {
    async getCustomer() {
      await this.$store.dispatch('getStatisticsCustomers', this.customerFilter);
    },
    async getBooking() {
      await this.$store.dispatch('getStatisticsBookings', this.bookingFilter);
    },
    async getProducts() {
      await this.$store.dispatch('getStatisticsProducts', this.productFilter);
    },
    getImage(icon) {
      icon == 'visa_master' ? (icon = 'visa') : '';
      icon == 'payment_card' ? (icon = 'visa') : '';
      return require(`@/assets/icon/${icon}.png`);
    },
    openPayment(id) {
      this.$router.push(`/payments/${id}`);
    },
    async getPaymentsList() {
      await this.$store
        .dispatch('getPaymentsList', {
          customer: 'all',
          search: '',
          status: 'all',
          page: this.pagePay,
          payMethod: 'all',
          from: '',
          to: '',
          sort: ['created_at_desc'],
        })
        .catch((e) => {
          if (e.response.data.error[0] === 'page__out_of_bounds') {
            this.pagePay = 1;
            this.getPaymentsList();
          }
        });
    },
    openRequest(id) {
      this.$router.push(`/trips-requests/${id}`);
    },
    async getRequestsTable() {
      await this.$store
        .dispatch('getRequestsList', {
          search: '',
          status: 'submitted',
          page: this.pageRequest,
          sort: ['created_at_desc'],
        })
        .catch((e) => {
          this.statusError = e.response.status;
          if (e.response.data.error[0] === 'page__out_of_bounds') {
            this.page = 1;
            this.getRequestsTable();
          }
        });
    },
    openBooking(id) {
      this.$router.push(`/trip-bookings/${id}/general`);
    },
    async getBookingTable() {
      await this.$store
        .dispatch('getBookingList', {
          customer: 'all',
          search: '',
          status: 'successful',
          page: this.page,
          sort: ['created_at_desc'],
        })
        .catch((e) => {
          if (e.response.data.error[0] === 'page__out_of_bounds') {
            this.page = 1;
            this.getBookingTable();
          }
        });
    },
    async getOrdersTable() {
      await this.$store
        .dispatch('getProductOrdersList', {
          search: '',
          status: 'all',
          sort: 'all',
          page: this.pageOrder,
        })
        .catch((e) => {
          if (e.response.data.error[0] === 'page__out_of_bounds') {
            this.pageOrder = 1;
            this.getOrdersTable();
          }
        });
    },
    chartData(data) {
      const objValue = [],
        objMeta = [],
        objValueT = [],
        objMetaT = [];
      for (let i = 0; i < data.series[0].length; i++) {
        const obj = {};
        obj.value = data.series[0][i];
        objValue.push(obj);
      }
      for (let i = 0; i < data.labels.length; i++) {
        const obj = {};
        obj.meta = data.labels[i];
        objMeta.push(obj);
      }
      const resultOne = objValue.map((e, i) => {
        return Object.assign({}, e, objMeta[i]);
      });
      // for (let i = 0; i < data.series[1].length; i++) {
      //   const obj = {};
      //   obj.value = data.series[1][i];
      //   objValueT.push(obj);
      // }
      for (let i = 0; i < data.labels.length; i++) {
        const obj = {};
        obj.meta = data.labels[i];
        objMetaT.push(obj);
      }
      const resultTwo = objValueT.map((e, i) => {
        return Object.assign({}, e, objMetaT[i]);
      });
      const result = [];
      result.push(resultTwo);
      result.push(resultOne);
      return result;
    },
  },
  watch: {
    paymentList(val) {
      if (val) {
        for (let i = 0; i < this.paymentList.result.length; i++) {
          this.arrayPay.push(this.paymentList.result[i]);
        }
      }
    },
    requestsTable(val) {
      if (val) {
        for (let i = 0; i < this.requestsTable.result.length; i++) {
          this.arrayRequestsTable.push(this.requestsTable.result[i]);
        }
      }
    },
    bookingTable(val) {
      if (val) {
        for (let i = 0; i < this.bookingTable.result.length; i++) {
          this.arrayBookingTable.push(this.bookingTable.result[i]);
        }
      }
    },
    ordersTable(val) {
      if (val) {
        for (let i = 0; i < this.ordersTable.result.length; i++) {
          this.arrayOrdersTable.push(this.ordersTable.result[i]);
        }
      }
    },
    customerChartist(val) {
      this.chartDataCustomer.labels = [];
      this.chartDataCustomer.series = [];
      if (val) {
        this.chartDataCustomer.labels = this.customerChartist.labels;
        this.chartDataCustomer.series = this.chartData(this.customerChartist);
        ++this.key;
      }
    },
    bookingChartist(val) {
      this.chartDataBooking.labels = [];
      this.chartDataBooking.series = [];
      if (val) {
        this.chartDataBooking.labels = this.bookingChartist.labels;
        this.chartDataBooking.series = this.chartData(this.bookingChartist);
        ++this.key;
      }
    },
    productsChartist(val) {
      this.chartDataProducts.labels = [];
      this.chartDataProducts.series = [];
      if (val) {
        this.chartDataProducts.labels = this.productsChartist.labels;
        this.chartDataProducts.series = this.chartData(this.productsChartist);
        ++this.key;
      }
    },
  },
  computed: {
    cardList() {
      return this.$store.getters.statisticsCounters;
    },
    customerChartist() {
      return this.$store.getters.statisticsCustomers;
    },
    bookingChartist() {
      return this.$store.getters.statisticsBookings;
    },
    productsChartist() {
      return this.$store.getters.statisticsProducts;
    },
    paymentList() {
      return this.$store.getters.paymentsList;
    },
    requestsTable() {
      return this.$store.getters.requestsList;
    },
    bookingTable() {
      return this.$store.getters.bookingList;
    },
    ordersTable() {
      return this.$store.getters.productOrdersList;
    },
    role() {
      return this.$store.getters.profile.role;
    },
  },
  destroyed() {
    this.$store.dispatch('setStatisticsCounters');
    this.$store.dispatch('setStatisticsCustomers');
    this.$store.dispatch('setStatisticsBookings');
    this.$store.dispatch('setStatisticsProducts');
    this.$store.dispatch('setPaymentsList');
    this.$store.dispatch('setRequestsList');
    this.$store.dispatch('setBookingList');
    this.$store.dispatch('setProductOrdersList');
  },
};
</script>

<style lang="scss">
.home {
  &-select {
    max-width: 100px !important;
    font-size: 12px !important;
    .v-input__slot {
      min-height: 28px !important;
    }
    .v-input__append-inner {
      margin-top: 2px !important;
    }
  }
  .ct-series-a .ct-point,
  .ct-series-a .ct-line,
  .ct-series-a .ct-bar {
    stroke: var(--v-gray-lighten1);
  }
  .ct-series-b .ct-point,
  .ct-series-b .ct-line,
  .ct-series-b .ct-bar {
    stroke: var(--v-primary-lighten2);
  }
}
</style>
